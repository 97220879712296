//Se importa la instancia de axios
import apiClient from "../../general/services/index.js";

//Ruta a la que se va acceder de la api
const relative_path = "/auth/";

export default {
  iniciarSesion(data_usuario) {
    return apiClient.post(relative_path + "iniciar-sesion", data_usuario);
  },
};
