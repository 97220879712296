<template>
  <v-container
    class="d-flex justify-center align-center"
    style="width: 100%; height: 90vh"
  >
    <v-row align="center" justify="center">
      <v-col cols="12" sm="10">
        <v-card class="elevation-6">
          <v-window v-model="step" touchless>
            <v-window-item :value="1">
              <v-row>
                <v-col cols="12" sm="6" md="6" lg="6">
                  <v-card-text class="mt-16">
                    <h1 class="text-center">Iniciar Sesión</h1>

                    <v-row
                      align="center"
                      justify="center"
                      class="mt-n8 mt-sm-n5 mt-md-n5 mt-lg-n5"
                    >
                      <v-col cols="12" sm="11" md="10" lg="10">
                        <v-form ref="formularioLogin">
                          <v-text-field
                            label="Correo"
                            v-model="correo"
                            :rules="[(v) => !!v || 'Correo Requerido']"
                            v-on:keyup.enter="iniciarSesion"
                            outlined
                            dense
                            color="blue"
                            autocomplete="false"
                            class="mt-16"
                          />
                          <v-text-field
                            v-model="contrasena"
                            v-on:keyup.enter="iniciarSesion"
                            :append-icon="contrasena.length > 0 ?ver_contrasena?'mdi-eye':'mdi-eye-off':'' "
                            @click:append="ver_contrasena = !ver_contrasena"
                            label="Contraseña"
                            :rules="[(v) => !!v || 'Contraseña Requerida']"
                            outlined
                            dense
                            color="blue"
                            autocomplete="false"
                            :type="ver_contrasena?'': 'password'"
                          />
                        </v-form>
                        <v-row class="mt-n6">
                          <v-col cols="12" class="text-end">
                            <a
                              class="caption blue--text"
                              :disabled="validando_datos"
                              @click="step = 2"
                              >Olvidaste tu contraseña?</a
                            >
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-btn
                            color="blue"
                            dark
                            block
                            class="mb-2"
                           
                            @click="iniciarSesion"
                            :loading="validando_datos"
                            >Iniciar Sesión</v-btn
                          >
                        </v-row>
                        <!-- <div
                          style="color:#ccc"
                            class="mt-3 align-center justify-center d-flex d-sm-none d-md-none d-lg-none"
                          >
                            <h4 class="">No tienes cuenta?</h4>
  
                            <h4 @click="step = 2" class="blue--text ml-1">
                              Registrate
                            </h4>
                          </div> -->
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-col>
                <v-col cols="6" class="d-none d-sm-flex d-md-flex d-lg-flex">
                  <v-col cols="12" class="blue rounded-bl-xl">
                    <div style="text-align: center; padding: 180px 0">
                      <v-card-text class="white--text mt-n1">
                        <h1 class="text-center">! Bienvenido ¡</h1>
                      </v-card-text>
                      <!-- <div class="text-center">
                          <v-btn outlined dark @click="step++">Registrate</v-btn>
                        </div> -->
                    </div>
                  </v-col>
                </v-col>
              </v-row>
            </v-window-item>
            <v-window-item :value="2">
              <v-row>
                <v-col cols="6" class="d-none d-sm-flex d-md-flex d-lg-flex">
                  <v-col cols="12" class="blue rounded-br-xl">
                    <div style="text-align: center; padding: 180px 0">
                      <v-card-text class="white--text">
                        <h2 class="text-center">
                          Ya recuperaste la contraseña?
                        </h2>
                      </v-card-text>
                      <div class="text-center">
                        <v-btn outlined dark @click="step = 1"
                          >Inicia Sesión</v-btn
                        >
                      </div>
                    </div>
                  </v-col>
                </v-col>

                <v-col
                  cols="12"
                  sm="6"
                  md="6"
                  lg="6"
                  class="d-flex justify-center align-center"
                >
                  <v-card-text>
                    <h1 class="text-center">Recuperar Contraseña</h1>
                    <h5 class="text-center mt-4 caption">
                      Se te enviara un correo para recuperar tu contraseña
                    </h5>

                    <v-row align="center" justify="center">
                      <v-col cols="12" sm="10">
                        <v-col cols="12">
                          <v-text-field
                            label="Correo"
                            outlined
                            dense
                            color="blue"
                            autocomplete="false"
                            class="mt-4"
                          />
                        </v-col>

                        <v-btn color="blue" class="mt-n4" dark block
                          >Enviar</v-btn
                        >
                        <div
                          style="color: #ccc"
                          class="mt-3 align-center justify-center d-flex d-sm-none d-md-none d-lg-none"
                        >
                          <h4 class="">Ya recuperaste la contraseña?</h4>

                          <h4 @click="step = 1" class="blue--text ml-1">
                            <a>Inicia Sesión</a>
                          </h4>
                        </div>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-col>
              </v-row>
            </v-window-item>
            <v-window-item :value="3">
              <v-row>
                <v-col cols="6" class="d-none d-sm-flex d-md-flex d-lg-flex">
                  <v-col cols="12" class="blue rounded-br-xl">
                    <div style="text-align: center; padding: 180px 0">
                      <v-card-text class="white--text">
                        <h2 class="text-center">Ya estas registrado?</h2>
                      </v-card-text>
                      <div class="text-center">
                        <v-btn outlined dark @click="step--"
                          >Inicia Sesión</v-btn
                        >
                      </div>
                    </div>
                  </v-col>
                </v-col>

                <v-col cols="12" sm="6" md="6" lg="6">
                  <v-card-text class="mt-12">
                    <h1 class="text-center mt-n4">Registrarse</h1>

                    <v-row align="center" justify="center">
                      <v-col cols="12" sm="10">
                        <v-row>
                          <v-col cols="12" sm="12" md="6" lg="6" class="mb-n9">
                            <v-text-field
                              label="Primer nombre"
                              outlined
                              dense
                              color="blue"
                              autocomplete="false"
                              class="mt-4"
                            />
                          </v-col>
                          <v-col cols="12" sm="12" md="6" lg="6">
                            <v-text-field
                              label="Segundo nombre"
                              outlined
                              dense
                              color="blue"
                              autocomplete="false"
                              class="mt-4"
                            />
                          </v-col>
                        </v-row>
                        <v-text-field
                          label="Correo"
                          outlined
                          dense
                          color="blue"
                          autocomplete="false"
                        />
                        <v-text-field
                          label="Contraseña"
                          outlined
                          dense
                          color="blue"
                          autocomplete="false"
                          type="password"
                        />
                        <v-row>
                          <v-col cols="12" class="d-flex">
                            <v-checkbox class="mt-n1" color="blue">
                            </v-checkbox>
                            <div style="padding-top: 2px">
                              <div class="caption d-flex">
                                <div>Acepto</div>
                                <span class="blue--text ml-1"
                                  >terminos y condiciones</span
                                >
                              </div>
                            </div>
                          </v-col>
                        </v-row>
                        <v-btn color="blue" dark block>Registrarse</v-btn>
                        <div
                          style="color: #ccc"
                          class="mt-3 align-center justify-center d-flex d-sm-none d-md-none d-lg-none"
                        >
                          <h4 class="">Ya tienes cuenta?</h4>

                          <h4 @click="step = 1" class="blue--text ml-1">
                            Inicia Sesión
                          </h4>
                        </div>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-col>
              </v-row>
            </v-window-item>
          </v-window>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import loginService from "./services/loginService";

export default {
  data: () => ({
    step: 1,

    validando_datos: false,
    correo: null,
    contrasena: "",

    ver_contrasena:false,
  }),
  methods: {
    async iniciarSesion() {
      if (this.$refs.formularioLogin.validate()) {
        this.validando_datos = true;
        await loginService
          .iniciarSesion({ correo: this.correo, contrasena: this.contrasena })
          .then((response) => {
            this.$store.commit("setToken", response.data.token);
            this.$store.commit("setUser", response.data.user);
            this.$store.commit("setRoles", response.data.roles);
            this.$router.push('/');
            
          })
          .catch((e) => { 
            console.log(e);
            this.$swal.fire({
            title: "Usuario o contraseña incorrectos",
            icon: "error",
            position: "center",
            showConfirmButton: true,
            confirmButtonText: "OK",
          });
          });

        this.validando_datos = false;
      }
    },
  },
};
</script>

<style scoped>
.v-application .rounded-bl-xl {
  border-bottom-left-radius: 300px !important;
}
.v-application .rounded-br-xl {
  border-bottom-right-radius: 300px !important;
}
</style>
